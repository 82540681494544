@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.containerSpinner {
  position: absolute;
  display: grid;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  background: #fff;
  z-index: 100;

  .loadingSpinner {
    width: 62px;
    height: 62px;
    display: inline-block;

    &::after {
      content: " ";
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 6px solid theme-color(primary);
      border-color: theme-color(primary) transparent theme-color(primary) transparent;
      animation: loadingSpinner 1.2s linear infinite;
    }
  }
}