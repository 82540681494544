.pid__title-1 {
  font-size: 48px !important;
  font-weight: 400;
}
.pid__title-2 {
  font-size: 24px !important;
  font-weight: 700;
}
.pid__title-3 {
  font-size: 20px !important;
  font-weight: normal;
  color: #707070;
  margin-bottom: 5px;
}

.pid__title-4 {
  font-size: 16px !important;
  font-weight: normal;
  color: #707070;
}

.pid__buttons-image {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 20;
}
.pid__buttons-image > span {
  // background-color: #fff;
  margin-left: 5px;
  // font-size: 18px;
  // height: 24px;
  // line-height: 24px;
  // width: 24px;
  // text-align: center;
  // border-radius: 5px;
  // color: #707070;
  // display: inline-block;

  // background-color: $button-primary-bg-color;
  //   margin-left: 5px;
  //   font-size: 18px;
  //   height: 24px;
  //   line-height: 24px;
  //   // width: 24px;
  //   text-align: center;
  //   border-radius: 5px;
  //   color:$button-primary-color;
  //   display: inline-block;
}

.pid__buttons-image-screen {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 20;
  background-color: #f9fafb;
  border-radius: 8px;
}
.pid__buttons-image-screen > span {
  margin: 4px;
}

.pid__btn-right {
  text-align: right;
}

/* .pid__btn-remove {
  color: #ff4d4f !important;
  cursor: pointer;
}

.pid__btn-create,
.pid__btn-create:focus {
  background-color: #00c853;
  border: 1px solid #00c853;
  box-sizing: border-box;
  color: #fff;
}

.pid__btn-create:hover {
  background-color: #fff;
  color: #00c853;
  border: 1px solid #00c853;
  box-sizing: border-box;
} */

.pid__btn-remove {
  color: theme-color(danger) !important;
  cursor: pointer;
}

.pid__btn-create,
.pid__btn-create:focus {
  background-color: $button-primary-bg-color;
  border: 1px solid $button-primary-border-color;
  box-sizing: border-box;
  color: $button-primary-color;
}

.pid__btn-create:hover {
  background-color: $button-secondary-bg-color;
  color: $button-secondary-color;
  border: 1px solid buton-secondary-border-color;
  box-sizing: border-box;
}

.pid__vertical-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-page-header-heading-title {
  line-height: 36px !important;
}

.halfbaked-default-button {
  background-color: #88cbc6;
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  height: 2.5rem;
  // border-radius: 0.5rem;
  outline: none;
}

.halfbaked-default-button:hover {
  background-color: #94d0cc;
  border-color: #94d0cc;
}

.halfbaked-text {
  color: #88cbc6 !important;
}

.halfbaked-text:hover {
  color: #94d0cc !important;
}

.ant-modal-content {
  border-radius: 20px;
  overflow: hidden;
}

.ant-modal-header {
  border-bottom: none;
}

.ant-modal-title {
  font-size: 24px;
  font-weight: bold;
}

.ant-form-item-control-input-content > input {
  border-bottom: 1px solid #dfdfdf;
  border-top: none;
  border-right: none;
  border-left: none;
  text-decoration-color: #dfdfdf;
}

.ant-form-item-control-input-content::placeholder {
  color: #dfdfdf;
}

.ant-modal-close {
  display: none;
}

.ant-modal-header {
  padding: 16px 24px 10px;
}

.ant-modal-body {
  padding: 14px 24px 10px;
}

.ant-btn {
  background: #88cbc6;
  border-color: #88cbc6;
  color: white;
  letter-spacing: 1.25px;
}

.ant-btn:hover {
  background-color: #94d0cc;
  border-color: #94d0cc;
  color: white;
}

/* ============================For Screens and Scenes=============================== */
.screen-and-scene-list {
  display: flex;
  gap: 16px;
}

.screen-and-scene-panel {
  width: 24%;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  box-shadow: 0px 1px 4px 0px #00000040;
}

.screen-and-scene-panel > img {
  width: 100%;
}

.screen-and-scene-title {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.screen-and-scene-title > p {
  font-size: 16px;
  margin-left: 16px;
}

.screen-and-scene-title > span {
  color: #707070;
  margin-right: 5px;
  cursor: pointer;
}

/* ============================For SchedulePage and ScenePage=============================== */
.custom-padding {
  padding: 1.5rem;
}

/* for sharing block------------------------------------------------- */

.select-user-and-access {
  display: flex;
  align-items: center;
  width: 100%;
}

.select-user {
  width: 60%;
}

.select-user > .ant-select-selector {
  border-right: none !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top: 1px solid #707070 !important;
  border-bottom: 1px solid #707070 !important;
  border-left: 1px solid #707070 !important;
}

.select-access {
  width: 30%;
}

.select-access > .ant-select-selector {
  border-left: none !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top: 1px solid #707070 !important;
  border-bottom: 1px solid #707070 !important;
  border-right: 1px solid #707070 !important;
}

.select-button {
  width: 20%;
  margin-left: 5%;
}

.edit-access > .ant-select-selector {
  border-radius: 8px !important;
  border: rgba(33, 33, 33, 0.08) !important;
  background: rgba(33, 33, 33, 0.08) !important;
  width: fit-content !important;
}
.ant-page-header-heading-title {
  line-height: 48px !important;
}
/* for sharing block------------------------------------------------- */

.site-layout {
  background-color: $content-bg;
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    background-color: $content-bg-dark;
    color: $body-color-dark;
  }
}

.widgetFrame {
  background-color: $content-bg;
  border: 1px solid $border-color;
  color: $body-color;
  position: relative;
  border-radius: 0.3rem;
  @at-root body[data-theme="dark"] #{&} {
    background-color: $content-bg-dark;
    border-color: $border-color-dark;
    color: $body-color-dark;
  }
}

//that one should be in socialBord.css

.toolbarClassName {
  background-color: #88cbc6;
}

.editorClassName {
  background-color: white;
}

.editorClassName:focus {
  border: 1px solid #88cbc6;
}

.no-display {
  display: none;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  height: 25px;
}
