.create-articles-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-article-btn {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.create-article-btn-phone {
  font-weight: bold;
  font-size: 12px;
  width: fit-content;
}

.articles-page-phone {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5% 5% 10% 5%;
}

.articles-page {
  display: flex;
  justify-content: space-between;
  // background-color: white;
  background-color: color(white);
  @at-root body[data-theme="dark"] #{&} {
    background-color: $content-bg-dark;
  }
}

.articles-width-post-visible {
  width: 68%;
  justify-content: center;
  display: flex;
}

.articles-width-post-invisible {
  width: 98%;
  justify-content: center;
  display: flex;
}

.big-news-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.big-news-panel > img {
  width: 100%;
}

.go-back-icon {
  display: flex;
  align-items: center;
  // color: rgba(0, 0, 0, 0.87);
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  cursor: pointer;
}

.news-panel {
  width: 49%;
}

.news-panel > img {
  width: 100%;
}

.news-body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.author-indicator {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.big-article-title {
  font-size: 24px;
  font-weight: bold;
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
  // color: rgba(0, 0, 0, 0.87);
}

.article-title {
  font-size: 20px;
  font-weight: bold;
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
  // color: rgba(0, 0, 0, 0.87);
}

.author-and-article {
  font-size: 16px;
  letter-spacing: 0.5px;
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
  // color: rgba(0, 0, 0, 0.87);
}

.show-posts-expand {
  display: flex;
  writing-mode: vertical-lr;
  padding: 24px 0px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  cursor: pointer;
  width: 2%;
}

.close-posts-collapse {
  display: flex;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  cursor: pointer;
}

.articles-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  width: 820px;
}

.articles-list-tablet {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  width: 100%;
}

.select-content-for-phone {
  border: none;
  width: fit-content;
  background-color: #f9f9f9;
  color: black;
  font-size: 25px;
  padding: 0px;
}

.select-content-for-phone:focus {
  background-color: #f9f9f9;
}

.option-content-for-phone {
  font-size: 20px;
}
/* ==========================NewsPanels============================== */
.centering {
  display: flex;
  flex-direction: column;
  padding: 34px 15%;
  gap: 15px;
  // background-color: white;
  background-color: color(white);
  @at-root body[data-theme="dark"] #{&} {
    background-color: $content-bg-dark;
  }
}

.centering-phone {
  display: flex;
  flex-direction: column;
  padding: 25px 5%;
  gap: 15px;
}

.author-name-area {
  display: flex;
  justify-content: space-between;
}

.author-area-left {
  display: flex;
  gap: 12px;
}

.author-area-right {
  display: flex;
  gap: 15px;
}
/* ==========================PostsPanel============================== */
.posts-feed {
  width: 32%;
  padding: 24px;
  // background-color: rgb(249, 249, 249);
  background-color: $content-bg;
  @at-root body[data-theme="dark"] #{&} {
    background-color: $content-bg-dark;
  }
}

.posts-feed-phone {
  width: 100%;
}

.addition-of-posts {
  display: flex;
  gap: 15px;
  margin: 15px 0px;
}

.author-avatar-image {
  width: 5%;
}

.post-input-and-submit {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.post-input {
  width: 100%;
  border: 1px solid /*rgba(0, 0, 0, 0.12)*/;
  border-radius: 8px;
  border-color: $border-color;
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    border-color: $border-color-dark;
  }
  font-size: 14px;
}

.post-submit {
  display: flex;
  justify-content: space-between;
}

.post-image-and-emoji {
  display: flex;
  // color: rgb(136, 203, 198);
  color: theme-color("primary");
  gap: 10px;
}

.list-of-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.post-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  // background-color: white;
  background-color: color(white);
  border-radius: 16px;
  box-shadow: 0px 1px 1px $border-color, 0px 2px 1px $border-color,
    0px 1px 3px $border-color;
  @at-root body[data-theme="dark"] #{&} {
    background-color: $content-bg-dark;
    box-shadow: 0px 1px 1px $border-color-dark, 0px 2px 1px $border-color-dark,
      0px 1px 3px $border-color-dark;
  }
  textarea {
    color: $body-color;
    border-radius: 4px;
    border-color: gainsboro;
    margin-top: 5px;
  }
}

.post-and-author {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
}

.post-and-comment-content {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
}

.post-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 16px;
}

.post-footer {
  display: flex;
  justify-content: space-between;

  .footer-icons {
    display: flex;
    align-items: center;
    // color: rgba(0, 0, 0, 0.6);
    color: color(gray);
    @at-root body[data-theme="dark"] #{&} {
      color: $body-color-dark;
    }
    gap: 2px;
    :hover {
      color: theme-color(primary);
    }
  }
}

.like-and-comment {
  display: flex;
  gap: 30px;
  cursor: pointer;
}

.comment-panel {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 16px;
  border-top: 1px solid gainsboro;
}

.comment-author-and-article {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

.post-comment-input {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  padding: 0px 16px 10px 16px;

  input {
    width: 95%;
    border: 1px solid /*rgba(0, 0, 0, 0.12)*/;
    border-radius: 8px;
    border-color: $border-color;
    color: $body-color;
    @at-root body[data-theme="dark"] #{&} {
      border-color: $border-color-dark;
    }
    font-size: 14px;
  }

  span {
    color: color(gray);
    @at-root body[data-theme="dark"] #{&} {
      color: $body-color-dark;
    }
    cursor: pointer;
    :hover {
      color: theme-color(primary);
    }
  }
}

.image-upload > input {
  display: none;
}

.custom-dragger .ant-upload-drag {
  border: none;
}

.custom-dragger .ant-upload-drag > .ant-upload {
  padding: 0px;
  // color: rgb(136, 203, 198);
  color: theme-color("primary");
}
/* ==========================CreateArticle============================== */

.create-page-header {
  display: flex;
  justify-content: space-between;
}

.create-page-header-phone {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.edit-buttons {
  display: flex;
  gap: 10px;
}

.edit-buttons-phone {
  display: flex;
  justify-content: space-between;
}

.create-page-header-left {
  display: flex;
  align-items: center;
  gap: 25px;
}

.create-page-header-right {
  display: flex;
  align-items: center;
  gap: 14px;
}

.pair-text-redactor {
  display: flex;
  gap: 10px;
}

.vertical-line {
  // color: rgba(0, 0, 0, 0.12);
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
  font-size: 24px;
}

.create-article-status {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  // color: rgba(0, 0, 0, 0.38);
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
}

.create-article-icons {
  cursor: pointer;
  // color: rgba(0, 0, 0, 0.6);
  color: $body-color;
  @at-root body[data-theme="dark"] #{&} {
    color: $body-color-dark;
  }
}

.title-input,
.subtitle-input,
.content-input {
  border: 1px solid /*#88cbc6*/;
  // color: rgba(0, 0, 0, 0.87);
  color: $body-color;
  border-color: $border-color;
}
.title-input {
  height: 60px;
  width: 100%;
  font-size: 48px;
  line-height: 56px;
  @at-root body[data-theme="dark"] #{&} {
    // color: $body-color-dark;
    border-color: $border-color-dark;
  }
}

.subtitle-input {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  @at-root body[data-theme="dark"] #{&} {
    // color: $body-color-dark;
    border-color: $border-color-dark;
  }
}

.content-input {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  @at-root body[data-theme="dark"] #{&} {
    // color: $body-color-dark;
    border-color: $border-color-dark;
  }
}

.title-input::placeholder,
.subtitle-input::placeholder,
.content-input::placeholder {
  opacity: 0.8;
}

.title-input:focus,
.subtitle-input:focus {
  border-color: theme-color("primary");
}

.big-header-stylization {
  font-size: 34px;
  line-height: 40px;
}

.small-header-stylization {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.15px;
}

.bold-stylization {
  font-weight: bold;
}

.italic-stylization {
  font-style: italic;
}

.toolbarClassName {
  // background-color: #88cbc6;
  background-color: theme-color("primary");
}

.editorClassName {
  // background-color: white;
  border: 1px solid $border-color;
  background-color: color(white);
  @at-root body[data-theme="dark"] #{&} {
    // background-color: $content-bg-dark;
    border-color: $border-color-dark;
  }
  [data-contents] {
    background-color: color(white);
    color: $body-color;
    padding: 0 10px;
  }
  &:hover,
  &:focus {
    border-color: theme-color(primary);
  }
}

.no-display {
  display: none;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  height: 25px;
}
