.custom-disable-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: $pid-color-mask;
  border-radius: $pid-border-radius-default;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}
