.custom-pid-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: none;
  background-color: transparent;

  &-primary {
    color: $pid-color-primary-normal;
  }

  &-secondary {
    color: $pid-color-half-dark;
  }

  &-border {
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    border: transparent;
    color: white;

    &-primary {
      background-color: $pid-color-primary-normal;
    }

    &-secondary {
      background-color: $pid-color-half-dark;
    }
  }

  &-disabled {
    cursor: not-allowed;
  }
}
