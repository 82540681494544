.custom-input {
  border: none;
  background: transparent;

  &-bottom-bordered {
    border-bottom: 1px solid $pid-color-outline;
    padding: 0.75rem 0.25rem;
  }

  &-full-bordered {
    border: 1px solid $pid-color-gray-dark;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
  }

  &-focused {
    border-color: $pid-color-primary-normal !important;
  }
}
