.settings-list-item {
  z-index: 1;
  border-bottom: none;
  border-radius: 10px;
  margin-bottom: 10px;
}

.settings-list-item:hover {
  background-color: #efefef;
  cursor: pointer;
}

.settings-list-item h4 {
  color: theme-color(primary);
  font-size: 18px;
}

.active > .settings-list-item {
  background-color: #efefef;
}

.ant-card-body {
  padding: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ant-list-item-meta {
  align-items: center;
}

.muted-text {
  color: #bfbfbf;
}

.muted-text:hover {
  color: #8c8c8c;
}

.ant-card-meta {
  display: flex;
  align-items: center;
}

.green-text {
  color: theme-color(primary);
}

.green-text:hover {
  color: theme-color(secondary);
}

.bigger-text {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.page-title-text {
  font-size: xx-large;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.regular-icon {
  font-size: large;
}

.ant-checkbox {
  float: right;
  top: -25px;
  right: 5px;
}

.pid__user-settings {
  background-color: #efefef;
  border-radius: 10px;
  padding-bottom: 50px;
}
/*.pid__user-settings>h2{
      margin:0
  }*/
.bigger-text {
  font-size: 20px;
  font-family: $type1;
}

.page-title-text {
  font-size: xx-large;
  font-family: type2;
  font-weight: 500;
}

.regular-icon {
  font-size: large;
}

.setting-screens-header {
  padding: 20px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pid__btn-create {
  float: right;
}

.setting-screen-list {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.setting-collapse {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.setting-collapse-panel-checked {
  width: 100%;
  background-color: rgba(186, 254, 249, 0.25);
  border: 2px solid #589a95;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 1px 4px 0px #00000040;
}

.setting-collapse-panel-default {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 1px 4px 0px #00000040;
}

.setting-collapse-panel-checked img {
  width: 100%;
  height: 180px;
  opacity: 0.5;
}

.setting-collapse-panel-default img {
  width: 100%;
  height: 180px;
}

.settings-collapse-screen-item {
  width: 25%;
  position: relative;
}

.settings-collapse-screen-item .ant-checkbox {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.show-all-screen {
  border: 2px solid rgba(0, 0, 0, 0.12);
  background: none;
  border-radius: 20px;
  width: 220px;
}

.show-user-screen {
  background: #e1fffc;
  border-radius: 20px;
  border: 2px solid #88cbc6;
  width: 240px;
  display: flex;
  align-items: center;
}
