.map-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.map-container .transform-component-module_wrapper__1_Fgj {
  width: unset !important;
  height: unset !important;
}

.react-transform-component {
  width: unset !important;
  height: unset !important;
}

.floor-selector {
  width: 80px;
  height: 240px;
  position: fixed !important;
  border-radius: 4px;
  margin: 20px 22px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px;
  z-index: 1;
}

.floor-option {
  width: 100%;
  height: 40px;
  background-color: white;
  border: 1px solid rgb(224, 224, 224);
  font-weight: bold;
}

.floor-option-selected {
  width: 100%;
  height: 40px;
  background-color: #bafef9;
  border: 1px solid rgb(224, 224, 224);
  font-weight: bold;
}

.floor-option:hover {
  background-color: #bafef9;
}

.show-map {
  width: 100%;
}

.map-sizing {
  width: 40px;
  height: 80px;
  position: fixed !important;
  border-radius: 4px;
  margin: 282px 42px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px;
  z-index: 1;
}

.sizing-button {
  width: 100%;
  height: 40px;
  background-color: white;
  border: 1px solid rgb(224, 224, 224);
}

.hover-popup {
  position: absolute;
  width: fit-content;
  height: 90px;
  z-index: 1;
  background-color: gainsboro;
  border-radius: 8px;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-info {
  display: flex;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.default-arrow {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid gainsboro;
}

.right-down-arrow {
  margin-left: 70%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid gainsboro;
}

.left-up-arrow {
  margin-top: -100px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid gainsboro;
}

.drawer-container {
  position: fixed;
  width: 260px;
  background-color: white;
  right: 0px;
  height: 100%;
  z-index: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.drawer-content {
  display: flex;
  flex-direction: column;
  margin: 15px 20px;
  gap: 10px;

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.drawer-label-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.edit-room-name {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  margin-left: auto;

  button {
    border: none;
    border-radius: 8px;
    width: fit-content;
    padding: 6px 12px;
    font-weight: bold;
    font-size: 14px;
  }

  .cancel-edit-button {
    background-color: #dddddd;
  }
  .submit-edit-button {
    background-color: #88cbc6;
    color: white;
  }
}

.drawer-input {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: 36px;
  padding: 0px 8px;
}

.drawer-input:focus {
  border: 1px solid black;
  // border-radius: 8px;
  // height: 36px;
  // padding: 0px 8px;
}

.drawer-name {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: fit-content;
  padding: 5px 5px;
  cursor: pointer;
}

.drawer-room-selector {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 5px 5px;
  color: black;
}

.drawer-owner {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: fit-content;
  padding: 5px 8px;
  background-color: #dddddd;
}

.fill-component-button {
  border: none;
  border-radius: 8px;
  width: fit-content;
  padding: 6px 12px;
  font-weight: bold;
  font-size: 14px;
  background-color: #88cbc6;
  color: white;
  margin-left: auto;
}

.icon-label-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: fit-content;

  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    z-index: 1;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 5px,
      rgba(0, 0, 0, 0.12) 0px 1px 10px, rgba(0, 0, 0, 0.2) 0px 2px 4px;
    border-radius: 8px;
    margin: auto;
  }
}

// mobile version
.center-map-vertical {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: white;
}

.center-map-horizontal {
  position: relative;
  width: 100%;
}
