a {
  color: black;
  font-weight: 400;
}

.scenes__item {
  // border: 1px solid #ececec;
  border: 1px solid color(gray-lightest);
  padding: 10px 20px;
  margin: 10px 0;
}

.scenes__item:hover {
  // background-color: #ececec;
  background-color: color(gray-lightest);
}

.upload-files {
  // border: 1px solid #ececec;
  border: 1px solid color(gray-lightest);
  margin: 10px 0;
  display: flex;
}
.upload-files:hover {
  // border: 1px solid #ccc;
  border: 1px solid color(gray-light);
}

.upload-files-preview {
  vertical-align: top;
  margin: 0 5px;
  width: 80px;
  display: inline-block;
  // background-color: #707070;
  background-color: color(gray);
}

.uploads-files-text {
  width: 95%;
  padding: 10px;
}

.upload-files > span {
  margin: 0 5px;
}

.uploads-files__btn {
  text-align: right;
  flex-grow: 1;
}
.uploads-files__btn span {
  padding: 10px 3px;
  display: block;
  // color: #707070;
  // background-color: #f9fafc;
  color: $button-primary-color;
  background-color: $button-primary-bg-color;
}
.uploads-files__btn span:hover {
  opacity: 0.5;
}

.green-text {
  color: theme-color(primary);
}

.green-text:hover {
  color: theme-color(secondary);
}

.muted-text {
  color: color(gray-light);
}

.muted-text:hover {
  color: color(gray);
}

.ant-card-meta {
  display: flex;
  align-items: center;
}

.bigger-text {
  font-size: 20px;
  // font-family: "Roboto", sans-serif;
  font-family: $type1;
}

.page-title-text {
  font-size: xx-large;
  // font-family: "Roboto", sans-serif;
  font-family: type2;
  font-weight: 500;
}

.regular-icon {
  font-size: large;
}

.pid__scenes-list {
  padding-bottom: 50px;
  margin: 10px 20px;
}

.pid__scenes-list h2 {
  margin-bottom: 0;
}
.pid_scenes-item > img {
  width: 100% !important;
  margin-right: 10px;
}

.pid_scenes-item > p a {
  // color: #096dd9;
  color: link-primary-color;
}
.pid_scenes-item > p a:hover {
  // color: #00c853;
  color: link-secondary-color;
}

.pid__scene-item {
  padding: 10px;
}

.pid__scene-item-left {
  min-height: 100vh;
}
.pid__scene-item-left img {
  width: 90%;
  margin-right: 10%;
}

.pid__scene-item-right {
  // background-color: #efefef;
  background-color: color(gray-lightest);
  min-height: 100%;
  padding: 10px 20px;
  border-radius: 10px;
}

.pid__scene-item__files {
  // background-color: #fff;
  background-color: color(white);
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
}
.pid__scene-item__files > span:first-child {
  padding: 10px 0;
  padding-left: 5px;
}

.pid__scene-item__files > img {
  padding: 10px 10px;
}

.pid__scene-item__title {
  flex-grow: 1;
  margin-left: 16px;
  margin: 8px 16px;
  position: relative;
}

.pid__scene-item__title > div {
  position: absolute;
  bottom: 0;
}

.pid__scene-item__btn {
  width: 40px;
  text-align: center;
  //  background-color: #f9fafc;
  background-color: color(gray-lightest);
}

.pid__scene-item__btn > span {
  display: block;
  height: 50%;
  position: relative;
  cursor: pointer;
}

.pid__scene-item__btn > span:first-child > span {
  position: absolute;
  bottom: 30%;
  left: 30%;
}

.pid__scene-item__btn > span:nth-child(2) > span {
  position: absolute;
  top: 30%;
  left: 30%;
}

.pid__scene-item__btn > span:hover {
  // background-color: #d6d4d4;
  background-color: color(gray-lighter);
}

.ant-checkbox + span {
  padding: 0 !important;
}

.alice-carousel__stage-item {
  width: 100% !important;
}
.pid__scene-item__img {
  border-radius: 5px;
  // background-color: #e6e6e6;
  background-color: color(gray-lightest);
  width: 180px;
  height: 100px;
  overflow: hidden;
  margin: 5px;
}

.pid__scene-item__img > img {
  width: 100%;
  min-height: 100%;
  height: auto;
}

.alice-carousel__wrapper {
  border-radius: 8px;
}

.image-wrapper-cover {
  height: 181px;
}

.image-wrapper {
  height: 216px;
  width: 384px;
}
