.pid-button-text {
  font-weight: 700;
  font-style: normal;
  font-size: 1.0625rem;
  line-height: 19.92px;
}

.pid-main-text {
  font-weight: 400;
  font-style: normal;
  font-size: 1.0625rem;
  line-height: 19.92px;
}

.pid-header-5-text {
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.pid-button-medium-text {
  font-weight: 500;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1rem;
}

.pid-body-1-text {
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}
