.screen-list__item {
  width: 100%;
  height: 180px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.screen-list__item-background {
  background-color: #e6e6e6;
  height: 100%;
  border-radius: 4px;
}

.pid__collapse {
  font-size: 15px !important;
  font-weight: bold;
  color: #ffffff !important;
  top: 16px !important;
  padding: 10px 15px !important;
  background-color: #88cbc6;
  border: none;
  transform: unset !important;
}
// .pid__collapse.ant-collapse-arrow {
//   font-size: 17px !important;
//   font-weight: bold;
//   color: #ffffff !important;
//   top: 22px !important;
// }

.screen-list__item > img {
  height: 100%;
}

.screen-list__item.item-empty {
  border: 2px dashed #6b6b6b;
}
.screen-list__item.item-empty:hover {
  cursor: pointer;
  border: 2px solid #6b6b6b;
}

.screen-list__item.item-empty > div {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.screen-list__item.item-empty > p {
  font-size: 20px;
}

// .green-default-button {
//   background-color: theme-color(primary);
//   border-color: theme-color(primary);
//   color: white;
//   text-transform: uppercase;
//   font-weight: 700;
//   padding-left: 0.1rem;
//   padding-right: 0.1rem;
//   height: 2.5rem;
//   border-radius: 0.5rem;
//   box-shadow: none;
// }

// .green-default-button:hover {
//   background-color: theme-color(secondary);
//   border-color: theme-color(secondary);
// }

.green-text {
  color: theme-color(primary);
}

.green-text:hover {
  color: theme-color(secondary);
}

.screen__delete-btn {
  float: right;
  color: #bfbfbf;
}

.screen__delete-btn:hover {
  color: theme-color(danger);
}

.muted-text {
  color: #bfbfbf;
}

.muted-text:hover {
  color: #8c8c8c;
}

.ant-card-meta {
  display: flex;
  align-items: center;
}

.bigger-text {
  font-size: 20px;
  font-family: $type1;
}

.page-title-text {
  font-size: xx-large;
  font-family: type2;
  font-weight: 500;
}

.regular-icon {
  font-size: large;
}

.uppercase {
  text-transform: uppercase;
}

.anticon {
  vertical-align: 0 !important;
}

/* -------------------------------------------------------------------- */

.check-all-checkbox {
  margin-left: 16px;
}

.group-panel {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  /* background-color: #EFEFEF; */
  margin: 10px 0px;
  padding: 14px 16px 16px;
  gap: 6px;
}

.panel-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox-and-name {
  display: flex;
  gap: 19px;
  align-items: center;
}

.checkbox-and-name > span {
  font-size: 27px;
  font-weight: bold;
}

.panel-title > a {
  font-size: 17px;
  font-weight: bold;
}

/* -------------------------------------------------------------------- */
.image-outer-container-hovered {
  background-color: black;
}

.image-outer-container-hovered > img {
  opacity: 0.5;
}

.eye-link {
  position: absolute;
  bottom: 25%;
  right: 5%;
  color: #efefef;
}

.eye-link:hover {
  color: #88cbc6;
}
