// Write your custom css/scss here
[data-widget][data-widget-status="loading"] > .loader-wrapper, [data-dashboard][data-dashboard-status="loading"] > .loader-wrapper{
  visibility: visible;
  opacity: 0.8;
  display: block;
  .loader{
    animation-iteration-count: infinite;
  }
}

[data-widget][data-widget-status="finished"], [data-dashboard][data-dashboard-status="finished"]{
  .loader-wrapper{
    display: none;
  }
}

[data-widget] > .loader-wrapper, [data-dashboard] > .loader-wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  .loader{
    overflow: hidden;
  }
}

.permissions-checkbox{
  width: 100%;
  margin-left: 15px;
}

.subpermission{
  margin-left: 25px;
}

.hidden{
  display: none;
}

.permission_open{
  position: absolute;
  margin-left: -20px;
  margin-top: -3px;
}

[data-dashboard="3cx"],[data-widget="3cx"] {
  .toolbarVideo {
    .card {
      flex-direction: row;
      .card-body {
        flex-direction: column;
        justify-content: center;
        display: flex;
      }
      .call_company_btn_cont {
        max-width: 60px;
        width: 100%;
        .button_video, .button_end_video {
          width: 100%;
          height: 100%;
          text-align: center;
          .feather {
            height: 28px;
            width: 28px;
          }
        }
      }
    }
  }
}

[data-widget]{
  & > .card{
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    box-shadow: none;
  }
}

.card.grid-margin:last-child{
  margin-bottom: 0;
}

// table.custom_table{
//   max-width: 100% !important;
//   width: 100% !important;
// }

@function jalousie-background-boxshadow($n, $color, $color-space)
{
  $box-shadow: '';
  @for $i from 0 through $n {
    $shadows: '0 0 0 ' + ($i * 9 + 6) + 'px ' + $color;
    @if($i + 1 != $n){
      $shadows: $shadows + ' , 0 0 0 ' + ($i * 9 + 9) + 'px ' + $color-space;
    }
    $box-shadow: $box-shadow + $shadows + ' ,';
  }
  @return str_slice(unquote($box-shadow), 0, str_length($box-shadow)-1);
}

#loxone-panel {
  .loxonecard .row{
    height: 100%;
  }
  .loxonecard [data-moodlabel]{
    height: 40px;
  }
  .mdi-lightbulb-outline, .loxone-jalousie, .mdi-lock, .mdi-lock-open{
    font-size: 50px;
  }
  .mdi-lock, .mdi-lock-open{
    margin: 0 auto;
  }
 .loxone-jalousie{
    border: 2px solid;
    height: 70px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0 2px;
  }
  .jalousie-visual{
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    &:before{
      content: "";
      width: 100%;
      box-shadow: jalousie-background-boxshadow(50, color(grey), $content-bg);
      @at-root body[data-theme="dark"] #{&} {
        box-shadow: jalousie-background-boxshadow(50, $body-color-dark, $content-bg-dark);
      }
    }
    &:after{
      content: "";
      display: block;
      height: 100%;
      width: 75%;
      position: absolute;
      border-left: 2px solid;
      border-right: 2px solid;
      border-color: color(grey);
      @at-root body[data-theme="dark"] #{&}{
        border-color: $body-color-dark;
      }
    }
  }
 .mdi-arrow-down-bold-circle-outline, .mdi-arrow-up-bold-circle-outline{
    font-size: 20px;
  }
  .mdi-lightbulb-outline/*, .jalousie-down, .jalousie-up*/{
    &[data-state="on"]{
      color: theme-color(primary);
    }
  }
  // .loxone-jalousie{
  //   &[data-state="on"]{
  //     border-color: theme-color(primary);
  //     .jalousie-visual{
  //       &:before{
  //         box-shadow: jalousie-background-boxshadow(50, theme-color(primary), $content-bg);
  //         @at-root body[data-theme="dark"] #{&} {
  //           box-shadow: jalousie-background-boxshadow(50, theme-color(primary), $content-bg-dark);
  //         }
  //       }
  //       &:after{
  //         border-color: theme-color(primary);
  //       }
  //     }
  //   }
  // }
}

.loxonecard{
  text-align: center;
  width: fit-content;
  min-width: 135px;
  max-width: 135px;
  .row{
    align-items: center;
  }
}

label[data-required]:after, label.required:after{
  content: ' *';
  color: theme-color(primary);
}

@media (min-width: 768px){
  .center-block.justify-content-md-end, .dataTables_wrapper div.dataTables_paginate ul.pagination {
      justify-content: center !important;
  }
  #loxone-panel .mdi-arrow-down-bold-circle-outline{
      margin-left: -10px;
  }
}

@media (min-width: 992px){
  .main-wrapper .page-wrapper .page-content {
      padding: 0px !important;
  }
}

// .techlogic-logo {
//   font-weight: 900;
//   font-size: 25px;
//   letter-spacing: -1px;
//   color: theme-color(primary);
//   span {
//     color: theme-color(dark);
//     font-weight: 300;
//   }
//   &:hover {
//     color: theme-color(primary);
//   }
//   &.logo-light {
//     color: theme-color(primary);
//   }
// }

.form-control:focus, select:focus, .email-compose-fields .select2-container--default .select2-selection--multiple:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus, .tt-query:focus, .tt-hint:focus{
  border-color: theme-color(primary);
}

.btn-link{
  color: theme-color(primary);
}

.indent20{
  margin-left: 20px;
}

.form-group.noMargin{
  margin: 0px;
  .form-check{
    margin: 0px;
  }
  &.indent20{
    margin-left: 20px;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.table-responsive-collapse{
  display: table;
  td{
    white-space: pre-wrap;
  }
}
@media (max-width: 320px){
  .table-responsive-collapse td{
    display: block;
  }
}

.favstar{
  position: absolute;
}

#documentation{
  .docSidebar{
    .nav{
      margin-bottom: 0;
      padding-bottom: 0;
      .nav-item{
        .nav-link {
          display: flex;
          align-items: center;
          padding: 0;
          height: 32px;
          white-space: nowrap;
          color: $body-color;
          @at-root body[data-theme="dark"] #{&}{
            color: $body-color-dark;
          }
          &.btn{
            text-transform: none;
          }
          .link-icon, .link-title, .link-arrow {
            transition: all .3s ease;
          }
          .link-icon{
            width: 16px;
            height: 16px;
            fill: $body-color;
            position: absolute;
            color: inherit;
            @at-root body[data-theme="dark"] #{&}{
              color: $body-color-dark;
            }
          }
          .link-title{
            margin-left: 30px;
            font-size: 14px;
          }
        }
        &:hover{
          .nav-link{
            color: theme-color(primary);
          }
        }
        .sub-menu {
          padding: 0 0 15px 33px;
          .nav-item{
            .nav-link{
              height: 25px;
              color: $body-color;
              font-size: 13px;
              transition: all .3s ease-in-out;
              @at-root body[data-theme="dark"] #{&}{
                color: $body-color-dark;
              }
            }
            &:hover{
              .nav-link{
                color: theme-color(primary);
              }
            }
          }
        }
        &.nav-category{
          color: $body-color;
          font-size: 11px;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: .5px;
          margin-bottom: 5px;
          height: 15px;
          @at-root body[data-theme="dark"] #{&}{
            color: $body-color-dark;
          }
        }
      }
    }
  }
  p{
    margin-bottom: 15px;
  }
  ul{
    padding-left: 20px;
    margin-bottom: 15px;
  }
  h2{
    margin-bottom: 15px;
  }
  h3{
    margin-bottom: 10px;
  }
  h2, h3{
    color: theme-color(primary);
  }
  img{
    display: block;
    margin: 10px 0 0 0;
    max-width: 100%;
  }
  img + small{
    display: block;
    margin-bottom: 10px;
  }
}

/*#loxone-panel .mdi-arrow-down-bold-circle-outline{
  padding-left: 0px;
}


#loxone-panel .mdi-arrow-up-bold-circle-outline{
  padding-right: 0px;
}*/

.sidebar-brand{
  background: url('../assets/images/logo-consento-vision-center.png');
  @media
    screen and (-webkit-min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
      background: url('../assets/images/logo-consento-vision-center_x2.png');
  }
  width: 155px;
  height: 51px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: contain;
}

.login-brand{
  background: url('../assets/images/logo-consento-vision-center.png');
  @media
    screen and (-webkit-min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
      background: url('../assets/images/logo-consento-vision-center_x2.png');
  }
  display: block;
  margin: auto;
  width: 170px;
  height: 51px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: contain;
}

.card .card-title{
  color: $body-color;
  @at-root body[data-theme="dark"] #{&}{
    color: $body-color-dark;
  }
}

.table{
  thead{
    th{
      color: theme-color(primary);
    }
  }
}

.btn{
  text-transform: uppercase;
  border-radius: 0;
}

.page-content{
  .fc {
    .fc-timegrid-event-harness{
      overflow: hidden;
    }
    .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > *{
      background-color: color(white);
      z-index: 10;
      @media (min-width: 768px){
        top: calc(#{$navbar-height} + 50px);
      }
      @media (max-width: 767px){
        top: $navbar-height;
      }
      @at-root body[data-theme="dark"] #{&}{
        background-color: $content-bg-dark;
      }
    }

    .fc-header-toolbar{
      @media (min-width: 768px){
        top: $navbar-height;
        position: sticky;
        z-index: 10;
        background-color: color(white);
        height: 50px;
        padding-bottom: 15px;
        margin-bottom: 0;
        @at-root body[data-theme="dark"] #{&}{
          background-color: $content-bg-dark;
        }
      }
      @media (min-width: 992px){
        display: grid;
        grid-template-columns: 1fr 180px 1fr;
        .fc-toolbar-chunk:nth-child(2) {
          display: flex;
          justify-content: center;
        }
        .fc-toolbar-chunk:nth-child(3) {
          display: flex;
          justify-content: end;
        }
      }
    }
  }
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 3px color(grey-light);
  border-radius: 3px;
  // background: #fff;
  // @at-root body[data-theme="dark"] #{&}{
  //   background: #000;
  // }
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: theme-color(primary);
  border-radius: 3px;
}

iframe.mittagio-i{
  min-width: 100% !important;
  width: 100% !important;

  a{
    display: none;
  }

  .clearfix.premium {
    &>a{
      display: none;
    }
  }
}