/* Tables */

.table {
  margin-bottom: 0;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      color: $text-muted;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    line-height: 1;
    white-space: nowrap;
  }
  td {
    font-size: $default-font-size;
    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
  &.table-bordered {
    border-top: 1px solid $border-color;
    @at-root body[data-theme="dark"] #{&}{
      border-color: $border-color-dark;
    }
  }
  &.table-dark {
    thead {
      tr {
        th {
          color: lighten($text-muted, 30%);
        }
      }
    }
  }
}

table{
  &.dataTable{
    &.dtr-inline.collapsed {
      &> tbody > tr{
        &> td.dtr-control:before, &>th.dtr-control:before{
          background-color: theme-color(primary);
        }
        &.parent{
          &> td.dtr-control:before, &>th.dtr-control:before{
            background-color: theme-color(danger);
          }
        }
      }
    }
  }
}