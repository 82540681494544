:root {
  --primary-normal: #00bfa5;
  --primary-dark: #008e76;
  --primary-light: #5df2d6;
  --primary-light-disabled: rgba(93, 242, 214, 0.6);
  --primary-light-hover: rgba(93, 242, 214, 0.05);
  --primary-light-pressed: rgba(93, 242, 214, 0.25);
  --primary-light-pressed-non-transparent: #d6fcf5;

  --grey-normal: #dfdfdf;
  --grey-light: #efefef;
  --grey-half-dark: #aaaaaa;
  --grey-dark: #707070;
  --grey-hover: rgba(0, 0, 0, 0.04);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  overflow-y: auto !important;
  overflow-x: auto !important;
  font-family: $type1;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: theme-color(dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: color(white);
}

.App-link {
  color: theme-color(primary);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.widget-list {
  border: 1px solid color(gray);
  height: 400px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.draggable-widget-item {
  font-size: large;
  color: theme-color(primary);
  border: 1px solid color(gray);
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.react-draggable {
  margin: 0 !important;
}

.pid-button-success {
  // background-color: $button-primary-bg-color;
  // border-radius: 0.5rem;
  // color: $button-primary-color;
  // border: none;
}

.pid-button-success:hover,
.pid-button-success:focus {
  // background-color: $button-secondary-bg-color;
  // color: $button-secondary-color;
  // border: none;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.dashboard-header-title {
  font-size: x-large;
  font-weight: 700;
}

.dashboard-header-button {
  text-transform: uppercase;
}

.dashboard-search-bar {
  width: 15%;
}

@media (max-width: 630px) {
  .dashboard-header {
    display: block;
    margin-bottom: 0.5rem;
  }

  .dashboard-header-title {
    font-size: x-large;
    font-weight: 700;
    display: flex;
    justify-content: center;
  }

  .dashboard-header-button {
    text-transform: uppercase;
    width: 100%;
  }

  .dashboard-search-bar {
    width: 100%;
  }
}

.pid-default-select .ant-select-selector {
  border-radius: 0.3rem !important;
}

.pid-default-select .ant-select-arrow {
  left: 11px;
  right: none;
}

.pid-default-select .ant-select-selection-search {
  left: 25px !important;
  right: 0 !important;
}

.pid-dashboard-list .ant-list-header {
  border: 0;
}

.pid-dashboard-list-item {
  border: 1px solid color(gray-light);
  padding: 0.5rem 1rem;
}

.pid-dashboard-list-item .ant-list-item-meta-title {
  margin: 0;
}

.pid-dashboard-list-item .ant-list-item:last-child {
  border-bottom: 1px solid color(gray-light) !important;
}

.pid-dashboard-table .ant-table-thead .ant-table-cell {
  font-weight: 700;
}

.pid-dashboard-table .ant-table-cell-fix-right-first {
  width: 5%;
}

.truncate {
  width: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pid-cc-select {
  font-size: small;
}

.pid-cc-select .ant-select-selector {
  padding: 0 !important;
  padding-right: 0.3rem !important;
}

.pid-cc-input {
  border: none;
  border-bottom: 1px solid color(gray-light);
  font-size: smaller;
  width: 100%;
}

.pid-cc-input:hover {
  border-bottom: 1px solid color(gray-lightest);
}

.pid-cc-input:focus {
  border-bottom: 1px solid color(gray-lightest);
}

.pid-cc-select {
  border-radius: 0.5rem;
  color: theme-color(dark);
  border: 1px solid color(gray-light);
}

.borderless {
  border: none !important;
}

.ant-layout {
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        .ant-collapse-arrow {
          &.btn-primary {
            color: $button-primary-color;
            @at-root body[data-theme="dark"] #{&} {
              color: $button-primary-color-dark;
            }
          }
        }
      }
    }
  }
  > .anticon {
    color: $body-color;
    @at-root body[data-theme="dark"] #{&} {
      color: $body-color-dark;
    }
  }
  .btn-primary {
    .anticon {
      color: inherit;
      @at-root body[data-theme="dark"] #{&} {
        color: inherit;
      }
    }
  }
}
.ant-modal-root {
  .ant-modal-content {
    background-color: $content-bg;
    color: $body-color;
    @at-root body[data-theme="dark"] #{&} {
      background-color: $content-bg-dark;
      color: $body-color-dark;
    }
    button.ant-btn-primary {
      background-color: $button-primary-bg-color;
      border-color: $button-primary-border-color;
      color: $button-primary-color;
      @at-root body[data-theme="dark"] #{&} {
        color: $button-primary-color-dark;
        background-color: $button-primary-bg-color-dark;
        border-color: $button-primary-border-color;
      }
    }
  }
}

.ant-modal-root,
.ant-layout {
  .ant-input {
    &:focus,
    &:hover,
    &.ant-input-focused {
      border-color: theme-color(primary);
      box-shadow: none;
    }
  }
  .ant-select:not(.ant-select-disabled):hover,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: theme-color(primary);
      box-shadow: none;
    }
  }
  .ant-spin-dot-item {
    background-color: theme-color(primary);
  }
}

// importing pid styles from /resources/js/apps/pid/css
@import "../../../js/apps/pid/css/styles";
