.custom-chip-wrapper {
  display: flex;
  align-items: center;
  background: $pid-color-gray;
  padding: 0.25rem 0.5rem;
  color: $pid-color-default-theme-high-emphasis;
  border-radius: $pid-border-radius-default;
}

.custom-chip-clickable {
  cursor: pointer;
}
