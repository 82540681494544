.custom-time-picker-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  .custom-time-picker-value-display {
    background-color: $pid-color-gray;
    border-radius: 0.5rem;
    border: none;
    padding: 0.25rem 0.5rem;

    &-disabled {
      cursor: not-allowed;
    }
  }
}

.custom-time-picker-dropdown {
  display: flex;
  flex-direction: column;
  width: 10rem;
  background-color: white;
  border-radius: $pid-border-radius-default;
  overflow: hidden;
  box-shadow: $pid-default-box-shadow;

  &-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: $pid-color-primary-normal;
    color: white;
    padding: 0.5rem;
  }

  &-body {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }
}

.custom-range-picker-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .custom-range-picker-items {
    display: flex;
    align-items: center;
    gap: 1rem;

    .custom-range-picker-item {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .custom-range-picker-error {
    display: none;
    align-items: center;
    color: $pid-color-error;
    overflow: hidden;
  }

  .custom-range-picker-error-show {
    display: flex;
  }
}

.custom-time-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
