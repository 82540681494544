.mobile-app-container {
  height: calc(100vh - calc(100vh - 100%));
  background: linear-gradient(
      162.76deg,
      #ffffff 5.82%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    linear-gradient(180deg, #b5e4fa 0%, #aef8e1 100%);
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
}

.for-pages {
  padding: 12px 16px 4px 16px;
}

.place-for-header {
  height: 8.8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  padding: 0px 20px;

  p {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .choose-blog-or-social-board {
    display: flex;
    gap: 30px;
    text-align: center;

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.15px;
    }
    .chosen-one {
      color: #589a95;
      border-bottom: 2px solid #589a95;
    }
  }
}

.blog-body {
  display: flex;
  flex-direction: column;
  height: 91.2%;
  overflow-y: auto;
  padding: 30px 20px 60px 20px;
  gap: 30px;
}

.blog-body::-webkit-scrollbar {
  width: 5px;
}

.blog-panel {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.blog-info {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}

.blog-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

.blog-subtitle {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
}

.blog-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;

  .blog-main-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.divider-line {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.create-edit-article-page {
  height: 100vh;
  background-color: white;
}

.title-and-cover-image {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  .custom-dragger-mobile .ant-upload-drag {
    border: none;
  }

  .custom-dragger-mobile .ant-upload-drag > .ant-upload {
    padding: 0px;
    background-color: white;
  }
}

.container-for-inputs {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 5px;

  .input-title-and-subtitle-mobile {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title-input-mobile {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.38);
      border: none;
    }

    .title-input-mobile:focus {
      border: 1px solid #e8ebf1;
    }

    .subtitle-input-mobile {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
      border: none;
    }

    .subtitle-input-mobile:focus {
      border: 1px solid #e8ebf1;
    }
  }
}

.mobileToolbarClassName {
  border: none;
}

.mobileEditorClassName {
  background-color: #ffffff;
}
// ----------------------------------------------------------------------------------

.social-board-body {
  display: flex;
  flex-direction: column;
  height: 91.2%;
  overflow-y: auto;
  padding: 30px 0px 60px 0px;
  gap: 20px;
}

.post-mobile-panel {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  .post-info {
    display: flex;
    justify-content: space-between;

    .post-name-date {
      display: flex;
      flex-direction: column;
      .post-name {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}

.post-page {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
  gap: 8px;
  overflow-y: auto;

  .post-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 20px;
  }

  .comment-panel-mobile {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 20px;
  }

  .post-input-mobile {
    padding: 0px 20px;
    border: none;
    color: $body-color;
    @at-root body[data-theme="dark"] #{&} {
      border-color: $border-color-dark;
    }
    font-size: 14px;
  }
}

// ----------------------------------------------------------------------------------
.notification-page-body {
  display: flex;
  flex-direction: column;
  height: 91.2%;
  overflow-y: auto;
  padding: 0px 0px 40px 0px;
  gap: 8px;
}

.date-zones {
  display: flex;
  flex-direction: column;

  .date-zone-label {
    padding: 0px 20px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
  }
}

.checked {
  background-color: rgba(136, 203, 198, 0.2);
}

.notification-panel {
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .notification-image {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .notification-body {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .notification-title-and-type {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .notification-title {
        max-width: 60%;
        overflow: hidden;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.87);
      }

      .notification-type {
        width: fit-content;
        font-size: 12px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .notification-description {
      max-width: 100%;
      font-size: 12px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.87);
    }

    .notification-datetime {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
