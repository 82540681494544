.custom-icon {
  cursor: pointer;
}

.custom-icon-primary {
  color: $pid-color-primary-normal;
}

.custom-icon-secondary {
  color: $pid-color-default-theme-medium-emphasis;
}

.custom-icon-disabled {
  color: $pid-color-half-dark;
  cursor: not-allowed;
}
