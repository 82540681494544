// Here should be darkmode styling for plugins and so on

body[data-theme="dark"]{
  .card{
    background-color: $content-bg-dark;
    color: $body-color-dark;
    border-color: $border-color-dark;
  }
  .text-muted{
    color: $body-color-dark !important;
  }

  .ant-layout{
    background-color: $content-bg-dark;
  }

  .table{
    border-color: $border-color-dark;
    color: $body-color-dark;
    td, th{
      border-color: $border-color-dark;
    }
  }

  .border{
    border-color: $border-color-dark !important;
  }

  .table-striped tbody tr:nth-of-type(odd){
    background-color: color(gray);
  }

  // .fc table, .table-bordered{
  //   border-color: $border-color-dark;
  //   border-color: $border-color-dark;
  // }
  .bootstrap-datetimepicker-widget{
    background-color: $content-bg-dark;
    color: $body-color-dark;
    border-color: $border-color-dark;
  }

  .list-group-item{
    background-color: $content-bg-dark;
    color: $body-color-dark;
    border-color: $border-color-dark;
    &:hover{
      background-color: $content-bg-hover-dark;
    }
  }
}