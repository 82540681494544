.nav {
  &.nav-tabs {
    border-color: $border-color;
    @at-root body[data-theme="dark"] #{&}{
      border-color: $border-color-dark;
    }
    .nav-item {
      margin-bottom: -1px;
      .nav-link {
        border-color: $border-color;
        border-bottom: 0;
        color: $body-color;
        background-color: rgba(theme-color(dark), .025);
        @at-root body[data-theme="dark"] #{&}{
          border-color: $border-color-dark;
          color: $body-color-dark;
          background-color: rgba($content-bg-dark, .025);
        }
        &.active {
          background-color: $card-bg;
          color: theme-color(primary);
          border-bottom: none;
          @at-root body[data-theme="dark"] #{&}{
            border-bottom: none;
            color: theme-color(primary);
            background-color: $content-bg-dark;
          }
        }
        &.disabled {
          background-color: transparent;
          color: $text-muted;
          border-color: rgba($border-color, .7);
          @at-root body[data-theme="dark"] #{&}{
            border-color: rgba($border-color-dark, .7);
          }
        }
      }
    }
    &.nav-tabs-vertical {
      border-bottom: 0;
      .nav-link {
        width: 100%;
        border: 1px solid $border-color;
        border-radius: 0;
        color: $body-color;
        background-color: $content-bg;
        @at-root body[data-theme="dark"] #{&}{
          border-color: $border-color-dark;
          color: $body-color-dark;
          background-color: $content-bg-dark;
        }
        &:first-child {
          border-radius: .25rem .25rem 0 0;
        }
        &:last-child {
          border-radius: 0 0 .25rem .25rem;
        }
        &.active {
          background-color: $card-bg;
          color: theme-color(primary);
        }
        &:not(:last-child) {
          border-bottom: 0;
        }
      }
    }
    &.nav-tabs-line {
      .nav-link {
        border: 0;
        background-color: transparent;
        &.active {
          border-bottom: 2px solid theme-color(primary);
        }
      }
    }
  }
}
.tab-content {
  &.tab-content-vertical {
    border: 1px solid $border-color;
    height: 100%;
  }
}