/* Footer */

.footer {
  background: $footer-bg;
  color: $footer-color;
  padding: 15px 25px 15px;
  border-top: 1px solid $border-color;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-family: $type1;
  font-weight: 400;
  margin-top: auto;
  @at-root body[data-theme="dark"] #{&} {
    color: $footer-color-dark;
    background-color: $footer-bg-dark;
    border-color: $border-color-dark;
  }
  a {
    color: theme-color(primary);
    font-size: inherit;
  }
  svg {
    width: 14px;
    height: 14px;
  }
  @media (max-width: 991px) {
    padding-bottom: 25px;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}
