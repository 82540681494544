.pid-default-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.625rem;
}

.pid-additional-icon-wrapper {
  display: flex;
  align-items: center;
}
