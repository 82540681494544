.schedule-page-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background: $pid-color-gray;
  border-radius: $pid-border-radius-default;
  height: 100%;
  padding: 1.5rem;
  position: relative;

  .schedule-page-item {
    display: flex;
    align-items: center;
    height: 7.125rem;
    width: 100%;
    background: $pid-color-main-theme-surface;
    border-radius: $pid-border-radius-default;
    overflow: hidden;
    position: relative;
    box-shadow: $pid-default-box-shadow;

    &-time-section {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: $pid-color-website-background;
      padding: 0.5rem 1.25rem;

      &-item {
        border-bottom: 1px solid $pid-color-outline;
        width: 100%;
        padding: 0.125rem 0.375rem;
        cursor: pointer;
      }
    }

    &-preview-section {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem;

      &-part {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 0.75rem;

        .schedule-page-item-preview-image {
          height: 5.625rem;
          width: 10rem;
        }

        .schedule-page-item-info {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          &-scene {
            display: flex;
            align-items: center;
            gap: 0.875rem;
          }
        }
      }
    }
  }

  .schedule-page-add-item-button {
    display: flex;
    width: 100%;
    align-items: center;
    background: $pid-color-gray;
    height: 3rem;
    padding: 1rem;
    color: $pid-color-default-theme-high-emphasis;
    border: none;
    border-radius: $pid-border-radius-default;
  }
}

.schedule-page-modal-body {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;

  .ant-select {
    border-bottom: 1px solid $pid-color-outline;
    padding: 0.35rem 0.25rem;
    height: 100%;
    width: 100%;
  }
}
