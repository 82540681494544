#root {
  .rbc-calendar {
    border: 5px solid color(gray-lightest);
    border-radius: 16px;
  }
  .rbc-time-view {
    border-radius: 16px;
    overflow: hidden;
  }
  .rbc-event {
    background-color: theme-color(success);
    border: 1px solid theme-color(success) !important;
    color: theme-color(dark);
  }
  .rbc-event-label {
    font-size: 14px;
    margin-right: 2rem;
    line-height: 15px;
  }

  .rbc-time-slot {
    min-height: 3rem;
    width: 6rem;
    text-align: center;
  }

  .rbc-event-content {
    font-size: 14px;
    line-height: 12px;
  }

  .rbc-label {
    font-size: 14px;
    color: color(gray-lighter);
  }

  .rbc-today {
    background-color: color(white);
  }

  // .green-default-button {
  //     background-color: $button-primary-bg-color;
  //     border-color: $button-primary-border-color;
  //     color: $button-primary-color;
  //     text-transform: uppercase;
  //     padding-left: 1.2rem;
  //     padding-right: 1.2rem;
  //     height: 2.5rem;
  // }

  // .green-default-button:hover {
  //     background-color: $button-secondary-bg-color;
  //     border-color: $button-secondary-border-color;
  //     color: $button-secondary-color;
  // }

  .green-text {
    color: theme-color(primary);
  }

  .green-text:hover {
    color: theme-color(secondary);
  }

  .muted-text {
    color: color(gray-lighter);
  }

  .muted-text:hover {
    color: color(gray-light);
  }

  .ant-card-meta {
    display: flex;
    align-items: center;
  }

  .bigger-text {
    font-size: 20px;
    font-family: $type1;
  }

  .page-title-text {
    font-size: xx-large;
    font-family: type2;
    font-weight: 500;
  }

  .regular-icon {
    font-size: large;
  }

  .modal-footer {
    position: absolute;
    bottom: 15px;
    left: 10px;
  }

  .modal-footer > button:first-child {
    margin-right: 10px;
  }

  .schedule-list {
  }

  .schedule-list-item {
    display: block;
    position: relative;
  }

  .schedule-list-item > a {
    display: block;
    border: 1px solid color(gray-lightest);
    font-size: 20px;
    font-weight: bold;
    color: theme-color(primary);
    padding: 10px 20px;
    margin-bottom: 10px;
    transition: 300ms;
    border-radius: 10px;
  }

  .schedule-list-item > a:hover,
  .schedule-list-item > a.active {
    border: 1px solid theme-color(primary);
    color: theme-color(primary);
    /* background-color:theme-color(primary);
    color:color(white); */
  }

  .schedule-list-item-btn {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 20px;
    font-weight: bold;
    color: theme-color(primary);
    padding: 10px 20px;
    margin-bottom: 10px;
    transition: 300ms;
  }
  .schedule-list-item-btn:hover {
    color: theme-color(danger);
  }

  .schedule-content {
    background-color: color(gray-lightest);
    padding: 10px 20px;
    min-height: 80vh;
    position: relative;
  }

  .schedule-edit {
    position: absolute;
    top: 13px;
    right: 20px;
    background-color: theme-color(primary);
    color: color(white);
    padding: 5px 15px;
  }

  .schedule-edit:hover {
    color: color(white) !important;
  }

  .schedule-content-scenes {
    font-weight: 400;
    padding: 5px 0;
  }

  .btn-create {
    // border: 1px solid theme-color(primary) !important;
    // color: theme-color(primary);
    // border-radius: 5px;
  }
  .btn-create:hover {
    // background-color: theme-color(primary);
    // color: color(white);
  }

  /*schedule page*/
  .schedule-page__badge {
    background-color: theme-color(danger);
    color: color(white);
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 50%;
  }

  .schedule-page__users > p {
    padding: 5px 10px;
    border-radius: 10px;
  }
  .schedule-page__users > p:hover {
    color: theme-color(primary);
  }
  .schedule-page__users > p > span {
    float: right;
    color: theme-color(primary);
    font-weight: bold;
  }

  .schedule-page__users > p > span:hover {
    color: theme-color(danger);
  }

  .schedule-page__users-select {
    margin-bottom: 30px;
    border-radius: 10px;
  }
}

/* for schedule------------------------------------------------- */

.schedule-panel {
  display: flex;
  padding: 5px 20px;
  gap: 16px;
}

.schedule-panel:hover {
  background-color: #efefef;
  border-radius: 10px;
}

.schedule-icon {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.schedule-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.schedule-info > span {
  font-size: 17px;
  font-weight: bold;
}

.schedule-info > p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.dropdown-customization {
  width: 350px;
  border-radius: 16px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 5px 5px rgba(0, 0, 0, 0.2) !important;
}

.link-customization {
  color: #707070 !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.preview-link {
  font-size: 17px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
}

.path-for-share {
  display: flex;
  height: 30px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 8px;
  background-color: #ffffff;
  align-items: center;
}

.input-path {
  color: #707070;
  width: 85%;
  text-align: left;
}

.copy-path {
  border-style: none;
  color: #88cbc6;
  width: 15%;
}

.create-schedule-btn {
  display: flex;
  align-items: center;
  /* background-color: #ffffff; */
  border: none;
  /* color: #707070; */
  box-shadow: none;
  /* border-radius: 8px; */
  font-size: 18px;
  height: 35px;
  padding: 5px 20px;
  /* width: 100%; */
  text-align: left;
  gap: 15px;
  margin: auto;
}

// .create-schedule-btn:hover {
//   background-color: #efefef;
//   color: #707070;
// }

/* for schedule panel------------------------------------------------- */

.event-panel {
  width: 100%;
  height: 120px;
  display: flex;
  background-color: #f0fafd;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;

  .event-time-indicator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 10%;
    padding: 1% 2%;
  }
}

.event-panel-locked {
  width: 100%;
  height: 120px;
  display: flex;
  background-color: #f0fafd;
  border-radius: 8px;
  overflow: hidden;

  .event-time-indicator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 10%;
    padding: 1.5% 2.5%;
  }
}

.event-time-indicator > p {
  color: rgba(0, 0, 0, 0.38);
}

.event-time-indicator {
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
    gap: 50px;

    input {
      border: none;
      background: #f0fafd;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.event-scene-info {
  display: flex;
  background-color: white;
  width: 90%;
}

.event-scene-right {
  width: 25%;
  padding: 10px 15px;
}

.event-scene-right > img {
  width: 100%;
  height: 100%;
}

.event-scene-left {
  display: flex;
  width: 75%;
  padding: 10px 0px;
  justify-content: space-between;
}

.event-title-duration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .event-duration {
    width: fit-content;
    padding: 5px 5px;
    border-radius: 8px;
    font-size: 17px;
    line-height: 20px;
    background-color: rgba(33, 33, 33, 0.08);
    color: rgba(0, 0, 0, 0.87);
  }

  .event-duration-locked {
    padding: 5px 5px;
    border-radius: 8px;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.38);
  }
}

.event-edit-delete {
  display: flex;
  gap: 30px;
  padding: 40px 25px;
  color: rgba(0, 0, 0, 0.6);
}

.event-edit-delete > span {
  cursor: pointer;
}

.create-event-button {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 5px;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 8px;
  border: none;
  height: 48px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 1.25px;
}
