// @import url('https://fonts.googleapis.com/css?family=Overpass:300,400,600,700,800,900&display=swap');
@font-face {
  font-family: Abel;
  src: url('../assets/fonts/abel/Abel-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Overpass;
  src: url('../assets/fonts/overpass/Overpass-ExtraLight.ttf');
  font-weight: 200;
  font-display: swap;
}